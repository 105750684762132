var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"gsb-mobile-app-modal","hide-footer":"","size":"xl","title":"ลงทะเบียนระบบออโต้ Mymo by GSB"}},[_c('b-overlay',{attrs:{"show":_vm.isFetching}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.step ===1)?[_c('ValidationProvider',{attrs:{"name":"idCard","rules":"required|numeric|length:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"รหัสบัตรประชาชน"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"maxlength":"13","placeholder":"รหัสประจำตัวประชาชน 13 หลัก","type":"text"},model:{value:(_vm.form.idCard),callback:function ($$v) {_vm.$set(_vm.form, "idCard", $$v)},expression:"form.idCard"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"phoneNumber","rules":"required|length:10|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"เบอร์โทรศัพท์"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"maxlength":"10","placeholder":"เบอร์ที่ใช้ลงทะเบียนบัญชี","type":"text"},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"pin","rules":"required|length:6|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"พิน 6 หลัก"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"maxlength":"6","placeholder":"123456 PIN","type":"text"},model:{value:(_vm.form.pin),callback:function ($$v) {_vm.$set(_vm.form, "pin", $$v)},expression:"form.pin"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})]:[_c('ValidationProvider',{attrs:{"name":"otp","rules":"required|numeric|length:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"OTP"}},[_c('b-form-input',{attrs:{"aria-invalid":false,"state":errors[0] ? false : null,"maxlength":"5","placeholder":"OTP 5 หลัก","type":"text"},model:{value:(_vm.form.otp),callback:function ($$v) {_vm.$set(_vm.form, "otp", $$v)},expression:"form.otp"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],_c('div',{staticClass:"text-right"},[(_vm.step === 2)?_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.isFetching,"type":"button","variant":"outline-secondary"},on:{"click":function($event){_vm.step = 1}}},[_vm._v(" ย้อนกลับ ")]):_vm._e(),_c('b-button',{attrs:{"disabled":_vm.isFetching,"type":"submit","variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('buttons.submit'))+" ")])],1)],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }