<template>
  <b-modal
      id="gsb-mobile-app-modal"
      hide-footer
      size="xl"
      title="ลงทะเบียนระบบออโต้ Mymo by GSB"
  >
    <b-overlay :show="isFetching">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <template v-if="step ===1">
            <ValidationProvider
              v-slot="{ errors }"
              name="idCard"
              rules="required|numeric|length:13"
            >
              <b-form-group label="รหัสบัตรประชาชน">
                <b-form-input
                  v-model="form.idCard"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                  maxlength="13"
                  placeholder="รหัสประจำตัวประชาชน 13 หลัก"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="phoneNumber"
              rules="required|length:10|numeric"
            >
              <b-form-group label="เบอร์โทรศัพท์">
                <b-form-input
                  v-model="form.phoneNumber"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                  maxlength="10"
                  placeholder="เบอร์ที่ใช้ลงทะเบียนบัญชี"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="pin" rules="required|length:6|numeric">
              <b-form-group label="พิน 6 หลัก">
                <b-form-input
                  v-model="form.pin"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                  maxlength="6"
                  placeholder="123456 PIN"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </template>
          <template v-else>
            <ValidationProvider
              v-slot="{ errors }"
              name="otp"
              rules="required|numeric|length:5"
            >
              <b-form-group label="OTP">
                <b-form-input
                  v-model="form.otp"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                  maxlength="5"
                  placeholder="OTP 5 หลัก"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </template>

          <div class="text-right">
            <b-button
              v-if="step === 2"
              :disabled="isFetching"
              class="mr-2"
              type="button"
              variant="outline-secondary"
              @click="step = 1"
            >
              ย้อนกลับ
            </b-button>
            <b-button :disabled="isFetching" type="submit" variant="success">
              {{ $t('buttons.submit') }}
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'GsbAutoMobileAppModal',
  props: {
    agentBankAccountId: {
      type: String,
      required: true
    },
    gsbAccount: {
      type: Object,
      default: () => ({
        deviceId: '',
        phoneNumber: '',
        pin: '',
      })
    }
  },
  data() {
    return {
      form: {
        idCard: '',
        phoneNumber: '',
        pin: '',
        otp: ''
      },
      isFetching: false,
      step: 1
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (_, id) => {
      if (id === 'gsb-mobile-app-modal') {
        this.form = {
          idCard: this.gsbAccount?.idCard || '',
          phoneNumber: this.gsbAccount?.phoneNumber || '',
          pin: this.gsbAccount?.pin || '',
        }
        this.step = 1
      }
    })
  },
  methods: {
    ...mapActions(['fetchBankAccount', 'requestGSBOtp', 'submitGSBOtp']),
    async onSubmit() {
      this.isFetching = true
      if (this.step === 1) {
        const response = await this.requestGSBOtp({ id: this.agentBankAccountId, data: this.form })
        if (response) {
          this.step = 2
        }
      } else {
        const response = await this.submitGSBOtp({ id: this.agentBankAccountId, data: this.form })
        if (response) {
          this.$bvModal.hide('gsb-mobile-app-modal')
          await this.fetchBankAccount(this.agentBankAccountId)
        }
      }
      this.isFetching = false
    },
  }
})
</script>

<style scoped>

</style>